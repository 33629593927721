import http from "./http.service";
import { change } from "redux-form";
import SocketService from "./socket.service";
import Promisable from "./promisable.service";
import { authActions } from "redux/slices/auth";
import { modalActions } from "redux/slices/modal";
import OneSignalService from "./onesignal.service";
import { NavigateFunction } from "react-router-dom";
import { getAppDispatch } from "utils/dispatch.util";
import LocalStorage from "../utils/localstorage.util";

const AuthService = {
	login: async (data: any) => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));

		const [success, error]: any = await Promisable.asPromise(
			http.post("/login", data)
		);

		if (success) {
			const { user, token } = success.data.data;

			localStorage.setItem("token", `Bearer ${token}`);
			dispatch?.(authActions.setUser(user));

			OneSignalService.connect(user._id);
			SocketService.connect(user);
		}

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},

	splash: async () => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));

		http.setJWT();
		const [success, error]: any = await Promisable.asPromise(
			http.get("/splash")
		);

		if (success) {
			const { user } = success.data.data;
			dispatch?.(authActions.setUser(user));
		}

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},

	forgotPassword: async (data: any, navigate?: NavigateFunction) => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));

		const [success, error]: any = await Promisable.asPromise(
			http.post(`/forgotPassword`, data)
		);

		if (success) {
			const { expiry_time } = success.data.data;

			localStorage.setItem("email", data.email);
			dispatch?.(change("LoginForm", "expiry_time", expiry_time));
			navigate?.("/password-otp");
		} else navigate?.("/forgot-password");

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},

	verifyOTP: async (data: any, navigate?: NavigateFunction) => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));

		const [success, error]: any = await Promisable.asPromise(
			http.post(`/verifyOTP`, data)
		);

		if (success) navigate?.("/reset-password");

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},

	resetPassword: async (data: any, navigate?: NavigateFunction) => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));

		const [success, error]: any = await Promisable.asPromise(
			http.post(`/resetPassword`, data)
		);

		if (success) {
			localStorage.removeItem("email");
			navigate?.("/login");
		}

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},

	changePassword: async (data: any) => {
		const dispatch = getAppDispatch();
		dispatch?.(authActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.post(`/updatePassword`, data)
		);

		if (success) {
			const { user, token } = success.data.data;

			dispatch?.(authActions.setUser(user));
			dispatch?.(modalActions.closeModal());
			localStorage.setItem("token", `Bearer ${token}`);
		}

		dispatch?.(authActions.setLoading(false));
		return [success, error];
	},

	logout: () => {
		const dispatch = getAppDispatch();

		SocketService.disconnect();
		OneSignalService.disconnect();

		localStorage.removeItem("token");

		dispatch?.(authActions.logout());
		dispatch?.(authActions.setUser(null));
	},

	getUser: () => {
		const user = LocalStorage.getItem("user") || null;
		return user;
	},
};

export default AuthService;
