import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  openNavigationIds: string[];
  isSidebarOpen: boolean;
};

const initialState: InitialState = {
  openNavigationIds: [],
  isSidebarOpen: true,
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    // toggleSidebar with boolean
    toggleSidebarAction: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isSidebarOpen: action.payload,
      };
    },
  },
});

export const { toggleSidebarAction } = navigationSlice.actions;
export const { reducer: navigationReducer } = navigationSlice;
