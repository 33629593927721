import { lazy } from "react";

const Login = lazy(() => import("pages/Login"));
const PasswordOTP = lazy(() => import("pages/PasswordOTP"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));

const Profile = lazy(() => import("pages/Profile"));
const Drivers = lazy(() => import("pages/Drivers"));
const Bookings = lazy(() => import("pages/Bookings"));
const Vehicles = lazy(() => import("pages/Vehicles"));

const AddDriver = lazy(() => import("pages/AddDriver"));
const AddVehicle = lazy(() => import("pages/AddVehicle"));

const DriverDetails = lazy(() => import("pages/DriverDetails"));
const VehicleDetails = lazy(() => import("pages/VehicleDetails"));
const BookingDetails = lazy(() => import("pages/BookingDetails"));
const BookingConfirmation = lazy(
	() => import("pages/BookingConfirmation")
);

export { default } from "./AppRoutes";

export interface IRoute {
	path: string;
	element: JSX.Element;
}

export const public_routes: IRoute[] = [
	{ path: "/", element: <Login /> },
	{ path: "/password-otp", element: <PasswordOTP /> },
	{ path: "/reset-password", element: <ResetPassword /> },
	{ path: "/forgot-password", element: <ForgotPassword /> },
];

export const private_routes: IRoute[] = [
	{ path: "/profile", element: <Profile /> },

	{ path: "/upcoming-bookings", element: <Bookings type={"market"} /> },
	{ path: "/accepted-bookings", element: <Bookings type={"planned"} /> },
	{ path: "/past-bookings", element: <Bookings type="completed" /> },
	{ path: "/booking-details/:id", element: <BookingDetails /> },
	{ path: "/booking-confirmation/:id", element: <BookingConfirmation /> },

	{ path: "/chauffeurs", element: <Drivers /> },
	{ path: "/add-chauffeur", element: <AddDriver /> },
	{ path: "/update-chauffeur/:id", element: <AddDriver /> },
	{ path: "/chauffeur-details/:id", element: <DriverDetails /> },

	{ path: "/vehicles", element: <Vehicles /> },
	{ path: "/add-vehicle", element: <AddVehicle /> },
	{ path: "/update-vehicle/:id", element: <AddVehicle /> },
	{ path: "/vehicle-details/:id", element: <VehicleDetails /> },
];
