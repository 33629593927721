import { VehicleState } from ".";
import { SelectOption } from "components/atoms/Select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: VehicleState = {
	vehicles: [],
	vehicle: null,
	loading: true,
	vehicleOptions: [],
	availableVehicleOptions: [],
	partnerVehicles: [],
};

export const vehicleSlice = createSlice({
	name: "vehicle",
	initialState,
	reducers: {
		clear: (state) => {
			state.partnerVehicles = [];
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		addVehicle: (state, action) => {
			state.vehicles.push(action.payload);
		},
		updateVehicle: (state, action) => {
			const { id, vehicle } = action.payload;

			state.vehicles.every(({ _id }, i) => {
				if (id === _id) {
					state.vehicles[i] = vehicle;
					return false;
				}

				return true;
			});
		},
		setVehicle: (state, action) => {
			state.vehicle = action.payload;
		},
		setPartnerVehicles: (state, action) => {
			const vehicles = action.payload
			let options: SelectOption[] = [];

			vehicles.forEach(({ _id, model, license_plate, class_id }: any) =>
				options.push({ value: _id, label: `${model} ${license_plate} (${class_id.name})` })
			);
			state.partnerVehicles = options;
		},
		setVehicles: (state, action) => {
			const vehicles = action.payload;
			let options: SelectOption[] = [];
			let availableOptions: SelectOption[] = [];

			vehicles.forEach(
				({ _id, model, status, is_deleted, license_plate }: any) => {
					const option = {
						value: _id,
						label: `${model} | ${license_plate}`,
					};

					options.push(option);

					if (
						(status === "approved" || status === "under_review") &&
						!is_deleted
					)
						availableOptions.push(option);
				}
			);

			state.vehicles = vehicles;
			state.vehicleOptions = options;
			state.availableVehicleOptions = availableOptions;
		},
	},
});

const vehicleReducer = vehicleSlice.reducer;

export const vehicleActions = vehicleSlice.actions;
export default vehicleReducer;
