import List from '@mui/material/List';
import { NavigationItem } from './components/navigation-item/NavigationItem';
import { NavigationItemType } from './components/navigation-item/types';
import {
  PublicOutlined, AirportShuttleOutlined, FactCheckOutlined,
  AssignmentIndOutlined,
  PersonAddAltOutlined,
  DirectionsCarFilledOutlined,
  AddBoxOutlined,
} from '@mui/icons-material';
import { useMemo } from 'react';

export function Navigation() {

  const navigationItems: NavigationItemType[] = useMemo(
    () => [
      {
        header: 'Bookings',
      },
      {
        path: "/upcoming-bookings",
        label: 'Upcoming',
        icon: (props: any) => <PublicOutlined {...props} />,
      },
      {
        path: "/accepted-bookings",
        label: 'Accepted',
        icon: (props: any) => <AirportShuttleOutlined {...props} />,
      },
      {
        path: "/past-bookings",
        label: 'Past',
        icon: (props: any) => <FactCheckOutlined {...props} />,
      },
      {
        header: 'Chauffeurs',
      },
      {
        path: "/chauffeurs",
        label: 'Chauffeurs',
        icon: (props: any) => <AssignmentIndOutlined {...props} />,
      },
      {
        path: "/add-chauffeur",
        label: 'Add Chauffeur',
        icon: (props: any) => <PersonAddAltOutlined {...props} />,
      }, {
        header: 'Vehicles',
      },
      {
        path: "/vehicles",
        label: 'Vehicles',
        icon: (props: any) => <DirectionsCarFilledOutlined {...props} />,
      },
      {
        path: "/add-vehicle",
        label: 'Add Vehicles',
        icon: (props: any) => <AddBoxOutlined {...props} />,
      },
    ], []
  );

  const navigationItemsList = navigationItems.map((item) => {
    return <NavigationItem key={Object.values(item).toString()} item={item} />;
  });

  return (
    <List sx={{ width: '100%', maxWidth: 360, padding: 2 }} component='nav' aria-labelledby='nested-list-subheader'>
      {navigationItemsList}
    </List>
  );
}
