import { ClassState } from ".";
import { createSlice } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const initialState: ClassState = {
	classes: [],
	modelOptions: [],
	modelsDetails: {},
	classesByCity: {},
	classesOptions: [],
};

export const classSlice = createSlice({
	name: "class",
	initialState,
	reducers: {
		clear: (state) => {
			state.modelOptions = [];
			state.modelsDetails = {};
			state.classesOptions = [];
		},
		setClasses: (state, action) => {
			let details: any = {};
			let classesByCity: any = {};
			const classes = action.payload;
			let options: SelectOption[] = [];

			classes.forEach((classs: any) => {
				const { city_id } = classs;
				let city_name = city_id?._id || "Undefined";

				if (classesByCity[city_name])
					classesByCity[city_name].classes.push(classs);
				else
					classesByCity[city_name] = {
						classes: [classs],
						city_name: city_id?.name,
					};
			});

			Object.keys(classesByCity).forEach((city_name) => {
				classesByCity[city_name].classes.forEach((classs: any) => {
					classs.models.forEach((model: any) => {
						options.push({ label: model.name, value: model._id });
						details[model._id] = {
							...model,
							class_id: classs._id,
							class_name: classs.name,
						};
					});
				});
			});

			state.classes = classes;
			state.modelOptions = options;
			state.modelsDetails = details;
			state.classesByCity = classesByCity;
		},
	},
});

const classReducer = classSlice.reducer;

export const classActions = classSlice.actions;
export default classReducer;
