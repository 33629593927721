import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toggleSidebarAction } from 'redux/slices/sidebar/navigationSlice';

export const useNavigation = () => {
  const isSidebarOpen = useAppSelector((state) => state.navigation.isSidebarOpen);
  const dispatch = useAppDispatch();
  const toggleSidebar = useCallback(() => dispatch(toggleSidebarAction(!isSidebarOpen)), [dispatch, isSidebarOpen]);

  return { isSidebarOpen, toggleSidebar };
};
