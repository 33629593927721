import { DriverState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const initialState: DriverState = {
	drivers: [],
	driver: null,
	loading: true,
	driverOptions: [],
	availableDriverOptions: [],
};

export const driverSlice = createSlice({
	name: "driver",
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		addDriver: (state, action) => {
			state.drivers.push(action.payload);
		},
		updateDriver: (state, action) => {
			const { id, driver } = action.payload;

			state.drivers.every(({ _id }, i) => {
				if (id === _id) {
					state.drivers[i] = driver;
					return false;
				}

				return true;
			});
		},
		setDriver: (state, action) => {
			state.driver = action.payload;
		},
		setDrivers: (state, action) => {
			const drivers = action.payload;
			let options: SelectOption[] = [];
			let availableOptions: SelectOption[] = [];

			drivers.forEach(
				({
					_id,
					status,
					last_name,
					first_name,
					is_enabled,
					is_deleted,
				}: any) => {
					const option = {
						value: _id,
						label: `${first_name} ${last_name}`,
					};

					options.push(option);

					if (
						(status === "approved" || status === "under_review") &&
						!is_deleted &&
						is_enabled
					)
						availableOptions.push(option);
				}
			);

			state.drivers = drivers;
			state.driverOptions = options;
			state.availableDriverOptions = availableOptions;
		},
	},
});

const driverReducer = driverSlice.reducer;

export const driverActions = driverSlice.actions;
export default driverReducer;
