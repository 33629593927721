import { UserMenu } from '../user-menu/UserMenu';
import { Stack, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';

export const ToolbarElements = () => {

  return (
    <Stack direction={'row'} spacing={2}>
      <UserMenu />
    </Stack>
  );
};
