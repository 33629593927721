import { ZoneState } from ".";
import { SelectOption } from "components/atoms/Select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ZoneState = {
	zones: [],
	zone: null,
	loading: true,
	zoneOptions:[]

};

export const zoneSlice = createSlice({
	name: "zone",
	initialState,
	reducers: {
		clear: (state) => {
			state.zoneOptions = [];
			state.zone=null
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
	
		setZones: (state, action) => {
			const zones = action.payload;
			let options: SelectOption[] = [];
			zones.forEach(({ _id, name }: any) =>
				options.push({ value: _id, label: name })
			);
			state.zones = zones;
			state.zoneOptions = options;
		},
	},
});

const zoneReducer = zoneSlice.reducer;

export const zoneActions = zoneSlice.actions;
export default zoneReducer;
