import { config } from "config";
import { log } from "utils/logger.util";
import * as Sentry from "@sentry/react";
import ToasterService from "utils/toaster.util";
import { BrowserTracing } from "@sentry/tracing";

const SentryService = {
	init: () => {
		Sentry.init({
			// debug: true,
			enabled: true,
			maxBreadcrumbs: 30,
			tracesSampleRate: 1.0,
			attachStacktrace: true,
			environment: config.APP_ENV,
			dsn: config.SENTRY_PUBLIC_KEY,
			release: config.SENTRY_RELEASE_KEY,
			integrations: [new BrowserTracing()],
			ignoreErrors: [
				"NotAllowedError: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD",
			],
			tracesSampler: (samplingContext) => {
				const { location, transactionContext } = samplingContext;

				log({ samplingContext });

				if (location?.hostname === "localhost") return 0;
				if (transactionContext.op === "pageload") return 0.2;
				return 1.0;
			},
			beforeSend: (e, hint) => {
				let msg: string;
				const err = e.exception?.values?.[0];

				if (err) msg = `${err.type ? `${err.type}: ` : ""}${err.value}`;
				else msg = `${hint?.originalException?.toString()}`;

				log({ e, msg, hint });

				if (msg) ToasterService.showError(msg);

				const ignoreErrors = [
					"Loading chunk",
					"ChunkLoadError",
					"Failed to fetch",
					"Loading CSS chunk",
					"Unexpected token '<'",
					"Unexpected token 'var'",
					"Failed to load Stripe.js",
					"_reportEvent is not defined",
					"Target container is not a DOM element",
					'Cannot call method "setAttribute" of null',
				];

				ignoreErrors.forEach((ignoreError) => {
					if (msg.includes(ignoreError)) return null;
				});

				if (config.NODE_ENV === "development") return null;
				if (e.exception) Sentry.showReportDialog({ eventId: e.event_id });
				return e;
			},
		});

		log("sentry init");
	},
};

export default SentryService;
