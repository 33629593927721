import { lazy } from "react";
import { MODAL } from "redux/slices/modal";

const HandleStatus = lazy(() => import("./HandleStatus"));
const DisableModal = lazy(() => import("./DisableModal"));
const UploadDocument = lazy(() => import("./UploadDocument"));
const ChangePassword = lazy(() => import("./ChangePassword"));
const MarketTransferPopup = lazy(() => import("./MarketTransferPopup"));
const ConfirmationForm = lazy(() => import("./ConfirmationForm"));
const AssignVehicle = lazy(() => import("./AssignVehicle"));

export { default } from "./AppModal";

export type ModalMapper = {
	[key in MODAL]: "" | JSX.Element;
};

export const modalMapper: ModalMapper = {
	HANDLE_STATUS: <HandleStatus />,
	DISABLE_DRIVER: <DisableModal />,
	UPLOAD_DOCUMENT: <UploadDocument />,
	CHANGE_PASSWORD: <ChangePassword />,
	MARKET_TRANSFER: <MarketTransferPopup />,
	CONFIRMATION_FORM: <ConfirmationForm />,
	ASSIGN_DRIVER_VEHICLE: <AssignVehicle />,
};
