import { LoaderState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: LoaderState = {
	loading: false,
};

export const loaderSlice = createSlice({
	name: "loader",
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
	},
});

const loaderReducer = loaderSlice.reducer;

export const loaderActions = loaderSlice.actions;
export default loaderReducer;
