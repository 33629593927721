export { default, modalActions, modalSlice } from "./modalSlice";

export enum MODAL {
	HANDLE_STATUS = "HANDLE_STATUS",
	DISABLE_DRIVER = "DISABLE_DRIVER",
	UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT",
	CHANGE_PASSWORD = "CHANGE_PASSWORD",
	MARKET_TRANSFER = "MARKET_TRANSFER",
	CONFIRMATION_FORM = "CONFIRMATION_FORM",
	ASSIGN_DRIVER_VEHICLE="ASSIGN_DRIVER_VEHICLE"
}

export type ModalType = "" | keyof typeof MODAL;

export interface OpenModalState {
	data: any;
	width: any;
	type: ModalType;
	loading?: boolean;
}

export type ModalState = OpenModalState & {
	open: boolean;
};
